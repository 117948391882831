import React from 'react';
import Layout from '../components/Layout';
import Meta from '../components/Meta';

const NotFoundPage = () => {
  const title = '404 Not Found';

  return (
    <Layout>
      <Meta title={title} />
      <section className="section">
        <div className="container has-text-centered">
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">
            Sorry, the page you are looking for does not exist.
          </h2>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
